<template>
  <div class="invoiceEmail">
    <a-modal
      :width="480"
      :visible="isInvoiceEmailModal"
      :closable="false"
      :footer="false"
    >
      <div class="modal_box">
        <div class="box_title">发送邮箱</div>
        <div class="box_con">
          <div class="con_info">请输入您的接收邮箱</div>
          <div class="con_input">
            <a-input v-model="email" placeholder="1234532@qq.com" />
          </div>
        </div>
        <div class="box_button">
          <div class="all-btn-blue" @click="onCancel()">取消</div>
          <div class="all-btn-small" @click="onConfirm()">保存 <a-icon v-if="isLoading" type="loading" /></div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: [
    'isInvoiceEmailModal',  // 控制弹窗显示/隐藏  必填
    'invoiceRecordId',  // 发票id
  ],
  // 数据对象
  data () {
    return {
      email: '',
      isLoading: false, 
    }
  },
  // 事件处理器
  methods: {
    // 取消
    onCancel() {
      this.$emit('onCancelEmail')
    },
    // 确定
    onConfirm() {
      if (this.isLoading) {
        return
      }
      if (this.email  == '') {
        this.$message.warning('请输入电子邮箱')
        return
      } else if (!this.$regular.email.reg.test(this.email)) {
        this.$message.warning(this.$regular.email.msg)
        return
      }

      this.isLoading = true
      this.$ajax({
        url: "/hxclass-pc/invoice/email/resend",
        method: 'post',
        params: {
          email: this.email,
          invoiceRecordId: this.invoiceRecordId
        }
      }).then((res) => {
        this.isLoading = false
        if (res.code == 200 && res.success) {
          this.email = ''
          this.$message.success('发送成功');
          this.$emit('onConfirmEmail')
        } else {
          this.$message.error(res.message);
        }
      });
      // this.$emit('onConfirmEmail')
    },
  },
  // 生命周期-实例创建完成后调用
  created () { },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
  *{box-sizing: border-box;}
  /deep/.ant-modal-footer {
    display: none;
  }
  .modal_box {
    .box_title {
      text-align: center;
      font-size: 20px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 23px;
      margin-top: 8px;
    }
    .box_con {
      margin-top: 32px;
      .con_info {
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 19px;
      }
      .con_input {
        margin-top: 24px;
        .ant-input {
          width: 354px;
          height: 40px;
          border-radius: 2px;
        }
      }
    }
    .box_button {
      display: flex;
      justify-content: space-between;
      margin-top: 47px;
      margin-bottom: 23px;
      padding: 0 73px;
      .all-btn-blue, .all-btn-small {
        width: 113px;
        height: 36px;
        line-height: 36px;
        font-size: 14px;
      }
    }
  }
</style>
