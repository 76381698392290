<template>
  <!-- 申请发票 -->
  <div class="applyInvoice">
    <!-- 面包屑 -->
    <a-breadcrumb separator=">">
      <a-breadcrumb-item
        ><router-link to="/personalCenter/MyOrder"
          >我的订单</router-link
        ></a-breadcrumb-item
      >
      <a-breadcrumb-item style="color: #15b7dd">查看发票</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="content_box">
      <!-- 发票信息 -->
      <div class="box box1" v-if="invoiceList.length !== 0">
        <div class="box_title">发票信息</div>
        <table class="box_table">
          <tr>
            <th>序号</th>
            <th>发票状态</th>
            <th>发票内容</th>
            <th>抬头类型</th>
            <th>抬头名称</th>
            <th>申请时间</th>
            <th width="25%">电子发票</th>
          </tr>
          <!-- status 1开票中 2已开票 3换开中 4已换开 -->
          <tr v-for="(item, index) in invoiceList" :key="index">
            <td>0{{ index + 1 }}</td>
            <td>{{ getInvoiceType(item.invoiceStatus, item.isChange) }}</td>
            <td>商品明细</td>
            <td>{{ getInvoiceType1(item.type) }}</td>
            <td>{{ item.title }}</td>
            <td>{{ item.createTime }}</td>
            <td class="light">
              <span @click="goDetail(item)" v-if="item.invoiceStatus != 0"
                >发票详情</span
              >
              <span
                @click="
                  (isInvoiceEmailModal = true),
                    (invoiceRecordId = item.invoiceRecordId)
                "
                v-if="item.invoiceStatus == 2"
                >发送邮箱</span
              >
              <!-- <span
                @click="
                  (invoiceType = 2),
                    (isAddInvoiceModal = true),
                    (invoiceRecordId = item.invoiceRecordId)
                "
                v-if="item.invoiceStatus == 2"
                >申请换开</span
              > -->
              <span
                @click="onReplaceVoice(item.invoiceRecordId)"
                v-if="item.invoiceStatus == 2 && item.isChange == 0 && $route.query.payMethod != 3"
                >申请换开</span
              >
            </td>
          </tr>
        </table>
      </div>

      <!-- 申请发票 -->
      <div class="box box2" v-if="form.freightPrice || productList.length !== 0">
        <div class="box_title">申请发票</div>
        <div class="box_con">
          <div class="con_info">请选择需要开票的商品：</div>
          <div
            class="con_type"
            v-for="(item, index) in productList"
            :key="index"
          >
            <div class="type_title">{{ item.typeTxt }}</div>
            <div
              class="type_item"
              v-for="(itemI, indexI) in item.childList"
              :key="indexI"
            >
              <a-checkbox @change="changeMail($event, itemI.id)">
                <div class="item_check">
                  <div class="item_img">
                    <img :src="itemI.productPicture" :alt="item.typeTxt" />
                  </div>
                  <div class="item_txt">
                    <div class="txt_title">{{ itemI.productName }}</div>
                    <div class="txt_main">
                      <div class="main_price">
                        ¥
                        {{
                          itemI.payPrice ? itemI.payPrice.toFixed(2) : "0.00"
                        }}
                      </div>
                      <div class="main_num">数量：{{ itemI.productCount }}</div>
                    </div>
                  </div>
                </div>
              </a-checkbox>
            </div>
          </div>
          <!-- 运费 -->
          <div
            class="con_type"
            v-if="this.form.freightPrice && this.form.freightPrice > 0"
          >
            <div class="type_title">运费</div>
            <div class="type_item">
              <a-checkbox @change="haveFreight = !haveFreight">
                <div class="item_check">
                  <div
                    class="item_txt"
                    style="display: flex; align-items: center"
                  >
                    <div
                      class="txt_title"
                      style="padding-top: 0; line-height: 1"
                    >
                      运费
                    </div>
                    <div
                      class="txt_main"
                      style="margin-top: 0; margin-left: 10px"
                    >
                      <div class="main_price">
                        ¥ {{ this.form.freightPrice.toFixed(2) }}
                      </div>
                    </div>
                  </div>
                </div>
              </a-checkbox>
            </div>
          </div>
        </div>
      </div>

      <!-- 请选择发票信息 -->
      <div class="box3" v-if="form.freightPrice || productList.length !== 0">
        <div class="box_title">请选择发票信息</div>
        <div class="invoiceList">
          <div
            class="invoiceItem"
            :class="{ invoiceActive: invoiceIndex == index }"
            v-for="(item, index) in invoiceData"
            :key="index"
            @click="changeInvoice(index)"
          >
            <div class="message">
              <!-- 1.企业 2.机关执业单位 3.个人/非企业单位 4.其他 -->
              <img
                v-if="item.headerType == 1"
                src="@/assets/image/order/business.png"
                alt=""
              />
              <img
                v-if="item.headerType == 3"
                src="@/assets/image/order/personal.png"
                alt=""
              />
              <p class="headerType">
                {{ item.headerType == 1 ? "企业" : "个人" }}
              </p>
              <p class="name">{{ item.invoiceHeader }}</p>
            </div>
            <p class="code" v-if="item.headerType == 1">
              {{ item.dutyParagraph }}
            </p>
            <p class="email">{{ item.email }}</p>
            <div class="box_left_top">
              <div class="writeBox"></div>
            </div>
            <div class="box_left_bottom">
              <div class="writeBox"></div>
            </div>
            <div class="box_right_top">
              <div class="writeBox"></div>
            </div>
            <div class="box_right_bottom">
              <div class="writeBox"></div>
            </div>
          </div>
          <div
            class="invoiceAdd"
            :class="{
              invoiceAddT: invoiceData.length >= 3,
              invoiceAddL: invoiceData.length % 3 == 0,
            }"
            @click="(invoiceType = 1), (isAddInvoiceModal = true)"
          >
            <img src="@/assets/image/order/invoiceAdd.png" alt="" />
            <p>添加发票抬头</p>
          </div>
        </div>
      </div>

      <div class="box_button" v-if="form.freightPrice || productList.length !== 0">
        <div class="all-btn-blue" @click="goBack()">取消</div>
        <a-button
          class="all-btn-small"
          type="primary"
          v-if="!form.freightPrice && (invoiceIndex == -1 || !orderProductIds.length)"
          disabled
        >
          提交申请
        </a-button>
        <div v-else class="all-btn-small" @click="onSave()">提交申请</div>
      </div>

      <!-- 添加发票/换开发票 -->
      <invoiceModal
        :invoiceType="invoiceType"
        :isAddInvoiceModal="isAddInvoiceModal"
        :invoiceRecordId="invoiceRecordId"
        @onCancelAdd="onCancelAdd"
        @onConfirmAdd="onConfirmAdd"
      ></invoiceModal>
      <!-- 发送邮箱 -->
      <invoiceEmail
        :invoiceRecordId="invoiceRecordId"
        :isInvoiceEmailModal="isInvoiceEmailModal"
        @onCancelEmail="onCancelEmail"
        @onConfirmEmail="onConfirmEmail"
      ></invoiceEmail>
      <!-- 确定申请发票？ -->
      <TipsModal
        :isCancelBtnShow="isPublicTipsType == 1 ? true : false"
        :ConfirmBtnText="isPublicTipsType == 1 ? '确认' : '我知道了'"
        :isPublicTipsShow="isPublicTipsShow"
        :isConfirmLoading="isLoading"
        :tipsText="
          isPublicTipsType == 1
            ? '确认申请发票？'
            : '发票正在开具中，如有疑问，请联系工作人员，联系电话：400-800-9002转3。'
        "
        @setEvent="getEvent"
      />
    </div>
  </div>
</template>

<script>
import invoiceModal from "@/components/model/invoiceModal.vue"; // 添加发票抬头
import invoiceEmail from "@/components/model/invoiceEmail.vue"; // 发送邮箱
export default {
  // 可用组件的哈希表
  components: {
    invoiceModal, // 添加发票抬头
    invoiceEmail, // 发送邮箱
  },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      orderId: null, //订单id
      productList: [], //商品列表
      invoiceData: [], //发票抬头列表
      invoiceIndex: -1, //发票选中index
      orderProductIds: [], //选中的商品id
      isLoading: false, //提交中

      invoiceRecordId: -1, //发票id
      invoiceType: 1, //1添加发票 2换开发票
      isInvoiceEmailModal: false, //发送邮箱弹框
      isAddInvoiceModal: false, //发票抬头弹框
      isPublicTipsType: 1, //1确定申请 2提交成功
      isPublicTipsShow: false, //确定申请弹框
      // 发票列表
      invoiceList: [],
      haveFreight: false, //是否开运费

      form: {}
    };
  },
  // 事件处理器
  methods: {
    // 选择商品
    changeMail(e, id) {
      // 选中
      if (!!e.target.checked) {
        this.orderProductIds.push(id);
      }
      // 取消选中
      else {
        this.orderProductIds.map((item, index) => {
          if (item == id) {
            this.orderProductIds.splice(index, 1);
          }
        });
      }
    },
    // 选择运费
    changeHaveFreight(e) {
      this.haveFreight = !!e.target.checked;
    },
    // 选择发票信息
    changeInvoice(index) {
      this.invoiceIndex = index;
    },
    // 确定申请发票?
    onSave() {
      return this.$message.warning('请联系工作人员线下开具发票，联系电话：400-800-9002转3。')
      if (!this.orderProductIds.length && !this.haveFreight) {
        this.$message.info("请选择需要申请发票的商品");
        return;
      }
      if (this.invoiceIndex == -1) {
        this.$message.info("请选择发票抬头");
        return;
      }
      //1确定申请 2提交成功
      this.isPublicTipsType = 1;
      this.isPublicTipsShow = true;
    },
    // 申请发票
    getEvent() {
      // 确认申请
      if (this.isPublicTipsType == 1) {
        if (this.isLoading) {
          this.$message.info("提交申请中，请稍后");
          return;
        }
        this.isLoading = true;
        this.$ajax({
          url: "/hxclass-pc/invoice/apply",
          method: "post",
          params: {
            orderId: this.orderId,
            orderProductIds: this.orderProductIds.length
              ? this.orderProductIds
              : [],
            userInvoiceId: this.invoiceData[this.invoiceIndex].userInvoiceId,
            haveFreight: this.haveFreight, //是否开运费
          },
        }).then((res) => {
          if (res.code == 200 && res.success) {
            this.isPublicTipsShow = false; //关闭确认申请弹框
            this.isPublicTipsType = 2; //1确定申请 2提交成功
            this.isPublicTipsShow = true; //打开提交成功弹框
          } else {
            this.$message.error(res.msg);
          }
          this.isLoading = false; //关闭loading
        });
        return;
      }
      // 提交成功
      if (this.isPublicTipsType == 2) {
        this.isPublicTipsShow = false;
        this.$router.go(-1);
        return;
      }
    },
    // 发票详情
    goDetail(e) {
      if (e.invoiceStatus == 1) {
        // 开票中
        this.$message.info(
          "发票正在开具中，如有疑问，请联系工作人员，联系电话：400-800-9002转3。"
        );
      } else if (e.invoiceStatus == 2) {
        // 已开票
        // if (e.isChange) {
        //   // 换开中
        //   this.$message.info(
        //     "发票正在换开中，如有疑问，请联系工作人员，联系电话：400-800-9002转3。"
        //   );
        // } else {
          this.$router.push({
            path:
              "/invoice/InvoiceDetail?invoiceRecordId=" +
              this.$AES.encode_data(String(e.invoiceRecordId)) + 
              '&payMethod=' + this.$route.query.payMethod,
          });
        // }
      }
    },
    // 取消发送邮箱
    onCancelEmail() {
      this.isInvoiceEmailModal = false;
    },
    // 确定发送邮箱
    onConfirmEmail() {
      this.isInvoiceEmailModal = false;
    },
    // 添加发票弹框 - 取消
    onCancelAdd() {
      this.isAddInvoiceModal = false;
    },
    // 添加发票弹框 - 确定
    onConfirmAdd() {
      this.isAddInvoiceModal = false;
      // 发票抬头列表
      this.getInvoiceHeadList();
    },
    // 取消
    goBack() {
      this.$router.push("/personalCenter/MyOrder");
    },
    // 换开发票
    onReplaceVoice(invoiceRecordId){
      this.$router.push({
        path:
          "/invoice/replaceVoice?invoiceRecordId=" +
          this.$AES.encode_data(String(invoiceRecordId)),
      });
    },
    // 发票列表
    getInvoiceList() {
      this.$ajax({
        url: "/hxclass-pc/invoice/order-invoice/list",
        params: {
          orderId: this.orderId,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          res.data.map((item, index) => {
            if (item.invoiceStatus !== 0) {
              this.invoiceList.push(item);
            }
          });
          console.log(this.invoiceList);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 获取商品详情列表
    getInvoiceDetail() {
      this.$ajax({
        url: "/hxclass-pc/invoice/apply/" + this.orderId,
        // url: "/hxclass-pc/invoice/apply/34313",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.form = res.data;
          let productType = [
            "课程",
            "图书",
            "教具",
            "模拟考试",
            "电子照片",
            "延期",
            "补考",
            "直播",
            "其他",
          ];
          let map = res.data.productMap;
          for (let item in map) {
            this.productList.push({
              id: item,
              typeTxt: productType[item - 1],
              childList: map[item],
            });
          }
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 获取发票抬头列表
    getInvoiceHeadList() {
      this.$ajax({
        url: "/hxclass-pc/invoice/header/list",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.invoiceData = res.data;
        }
      });
    },
    // 发票列表-发票状态
    getInvoiceType(type, isChange) {
      // 开票中、换开中
      if (type == 1) {
        // 是否换开 1是 0否
        if (isChange == 0) {
          return "开票中";
        } else if (isChange == 1) {
          return "开票中";
        }
      }
      // 已开票、已换开
      else if (type == 2) {
        // 是否换开 1是 0否
        if (isChange == 0) {
          return "已开票";
        } else if (isChange == 1) {
          return "已开票";
        }
      }
    },
    // 获取发票类型
    getInvoiceType1(con) {
      if (con == 1) {
        return "企业";
      } else if (con == 3) {
        return "个人";
      }
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    // 订单id
    this.orderId = this.$AES.decode_data(this.$route.query.orderId);
    // 发票列表
    this.getInvoiceList();
    // 获取商品详情列表
    this.getInvoiceDetail();
    // 获取发票抬头列表
    this.getInvoiceHeadList();
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}
.applyInvoice {
  width: 1400px;
  margin: 0 auto;
  padding-top: 92px;
  .content_box {
    margin-top: 22px;
    margin-bottom: 40px;
    padding: 40px 40px 60px 40px;
    background: #fff;
    border-radius: 5px;
    .box {
      .box_title {
        display: flex;
        align-items: center;
        font-size: 24px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 500;
        color: #15b7dd;
        &::before {
          content: "";
          display: inline-block;
          width: 4px;
          height: 19px;
          margin-right: 8px;
          background: #15b7dd;
        }
      }
    }
    // 发票信息
    .box1 {
      margin-bottom: 40px;
      .box_table {
        width: 100%;
        margin-top: 24px;
        th {
          font-size: 16px;
          font-family: PingFang HK-Medium, PingFang HK;
          font-weight: 500;
          color: #333333;
          line-height: 24px;
          text-align: center;
          padding: 15px 0;
          background: #f3f3f3;
        }
        td {
          font-size: 16px;
          font-family: PingFang HK-Regular, PingFang HK;
          font-weight: 400;
          color: #666666;
          line-height: 24px;
          text-align: center;
          border-bottom: 1px solid #ebebeb;
          padding: 20px 0;
          &.light {
            color: #15b7dd;
            span {
              cursor: pointer;
              &::before {
                content: " | ";
              }
              &:nth-child(1) {
                &::before {
                  content: "";
                }
              }
            }
          }
        }
        tr {
          td:nth-child(1) {
            border-left: 1px solid #ebebeb;
          }
          td:nth-last-child(1) {
            border-right: 1px solid #ebebeb;
          }
        }
      }
    }
    // 申请发票
    .box2 {
      .box_con {
        .con_info {
          font-size: 18px;
          font-family: PingFang HK-Regular, PingFang HK;
          font-weight: 400;
          color: #333333;
          line-height: 27px;
          margin-top: 24px;
        }
        .con_type {
          margin-top: 24px;
          padding-bottom: 40px;
          border-bottom: 1px solid #f2f2f2;
          .type_title {
            font-size: 18px;
            font-family: PingFang HK-Medium, PingFang HK;
            font-weight: 500;
            color: #333333;
          }
          .type_item {
            margin-top: 24px;
            /deep/.ant-checkbox-wrapper {
              position: relative;
              width: 100%;
              padding-left: 36px;
            }
            /deep/.ant-checkbox {
              position: absolute;
              left: 0;
              top: 50%;
              margin-top: -10px;
            }
            /deep/.ant-checkbox-inner {
              width: 20px;
              height: 20px;
              border-radius: 50%;
            }
            /deep/.ant-checkbox-inner::after {
              // height: 8px;
              left: 25%;
            }
            /deep/.ant-checkbox + span {
              padding: 0;
            }
            .item_check {
              display: flex;
              border-radius: 8px;
              border: 1px solid #ebebeb;
              padding: 24px 20px;
              .item_img {
                width: 123px;
                // height: 107px;
                img {
                  width: 100%;
                  // height: 100%;
                }
              }
              .item_txt {
                margin-left: 20px;
                .txt_title {
                  font-size: 20px;
                  font-family: PingFang HK-Regular, PingFang HK;
                  font-weight: 400;
                  color: #333333;
                  line-height: 30px;
                  padding-top: 8px;
                }
                .txt_main {
                  display: flex;
                  margin-top: 21px;
                  .main_price {
                    font-size: 16px;
                    font-family: PingFang HK-Regular, PingFang HK;
                    font-weight: 400;
                    color: #ec6c01;
                    line-height: 24px;
                  }
                  .main_num {
                    font-size: 14px;
                    font-family: PingFang HK-Regular, PingFang HK;
                    font-weight: 400;
                    color: #333333;
                    line-height: 21px;
                    margin-left: 109px;
                  }
                }
              }
            }
          }
        }
      }
    }
    // 请选择发票信息
    .box3 {
      margin-top: 24px;
      .box_title {
        font-size: 18px;
        font-family: PingFang HK-Medium, PingFang HK;
        font-weight: 500;
        color: #333333;
        line-height: 27px;
      }
      .invoiceList {
        margin-top: 25px;
        display: flex;
        flex-wrap: wrap;
        .invoiceItem:nth-child(3n + 1) {
          margin: 0;
        }
        .invoiceItem:nth-child(n + 4) {
          margin-top: 18px;
        }
        .invoiceAdd {
          cursor: pointer;
          width: 32%;
          margin-left: 2%;
          padding-top: 48px;
          padding-bottom: 41px;
          text-align: center;
          border: 2px dashed #c4c4c4;
          img {
            width: 39px;
            height: 39px;
          }
          p {
            font-size: 18px;
            font-family: PingFang HK-Regular, PingFang HK;
            font-weight: 400;
            color: #666666;
            line-height: 27px;
            margin-top: 7px;
          }
        }
        .invoiceAddT {
          margin-top: 18px;
        }
        .invoiceAddL {
          margin-left: 0;
        }
        .invoiceItem {
          cursor: pointer;
          width: 32%;
          margin-left: 2%;
          padding: 12px 20px;
          border-top: 2px dotted #c4c4c4;
          border-bottom: 2px dotted #c4c4c4;
          position: relative;
          .box_left_top,
          .box_left_bottom,
          .box_right_top,
          .box_right_bottom {
            width: 25px;
            height: 25px;
            background: #c4c4c4;
            .writeBox {
              width: 21px;
              height: 21px;
              background: #ffffff;
            }
          }
          .box_left_top {
            position: absolute;
            left: -1px;
            top: -3px;
            .writeBox {
              position: relative;
              left: 4px;
              top: 4px;
            }
          }
          .box_left_bottom {
            position: absolute;
            left: -1px;
            bottom: -3px;
            .writeBox {
              position: relative;
              left: 4px;
            }
          }
          .box_right_top {
            position: absolute;
            right: -1px;
            top: -3px;
            .writeBox {
              position: relative;
              top: 4px;
            }
          }
          .box_right_bottom {
            position: absolute;
            right: -1px;
            bottom: -3px;
          }
          .email {
            margin-top: 16px;
            font-size: 16px;
            font-family: PingFang HK-Regular, PingFang HK;
            font-weight: 400;
            color: #666666;
            line-height: 24px;
          }
          .code {
            margin-top: 16px;
            font-size: 16px;
            font-family: PingFang HK-Regular, PingFang HK;
            font-weight: 400;
            color: #666666;
            line-height: 24px;
          }
          .message {
            display: flex;
            align-items: center;
            padding-bottom: 17px;
            border-bottom: 2px dashed #ebebeb;
            img {
              width: 20px;
              height: 20px;
              margin-right: 4px;
            }
            .headerType {
              font-size: 18px;
              font-family: PingFang HK-Regular, PingFang HK;
              font-weight: 400;
              color: #666666;
              line-height: 27px;
              margin-right: 18px;
            }
            .name {
              font-size: 18px;
              font-family: PingFang HK-Medium, PingFang HK;
              font-weight: bold;
              color: #333333;
              line-height: 27px;
            }
          }
        }
        .invoiceActive {
          border-top: 2px dotted #15b7dd;
          border-bottom: 2px dotted #15b7dd;
          .box_left_top,
          .box_left_bottom,
          .box_right_top,
          .box_right_bottom {
            background: #15b7dd;
          }
        }
      }
    }
    .box_button {
      display: flex;
      margin-top: 80px;
      .all-btn-blue {
        font-size: 16px;
        margin-right: 54px;
      }
      .all-btn-small {
        font-size: 16px;
      }
    }
  }
}
// 小屏分辨率内容区呈现1200px布局
@media screen and (max-width: 16000px) {
  .applyInvoice {
    width: 1200px;
    .content_box {
      .box .box_title {
        font-size: 22px;
      }
      .box2 .box_con {
        .con_info {
          font-size: 16px;
        }
        .con_type .type_item .item_check .item_txt .txt_title {
          font-size: 18px;
        }
      }
      .box3 .invoiceList .invoiceItem {
        .code {
          font-size: 14px;
        }
        .email {
          font-size: 14px;
        }
        .message {
          .headerType {
            font-size: 16px;
          }
          .name {
            font-size: 16px;
          }
        }
      }
    }
  }
}
</style>
